<template>
  <div style="width: 100%;background:#F0F3F8;min-width: 1440px">
    <Helper></Helper>
    <!--顶部导航栏-->
    <Navbar2 class="navHeader" navBg="F0F3F8"></Navbar2>
    <div class="uploadBox">
      <div class="title">
        <div>免费软件上传</div>
        <div>
          <el-button round style="color:white;background: rgba(31, 98, 238, 1)" @click="submit">提交</el-button>
        </div>
      </div>
      <div class="content">
        <el-form :model="params" ref="form" label-width="100px" :rules="rules">
          <el-form-item label="软件标题" prop="title">
            <el-input v-model="params.title" placeholder="请输入软件标题"></el-input>
          </el-form-item>
          <el-form-item label="迅雷网盘">
            <el-input v-model="params.xunlei" placeholder="请输入迅雷网盘链接"></el-input>
          </el-form-item>
          <el-form-item label="百度网盘">
            <el-input v-model="params.baidu" placeholder="请输入百度网盘链接"></el-input>
          </el-form-item>
          <el-form-item label="夸克网盘">
            <el-input v-model="params.quark" placeholder="请输入夸克网盘链接"></el-input>
          </el-form-item>
          <el-form-item label="选择分类" prop="fidPath">
            <el-cascader
                :props="typeOptionProps"
                v-model="params.fidPath"
                :options="typeList"></el-cascader>
          </el-form-item>
          <el-form-item label="运行系统" prop="system">
            <div style="display: flex">
              <el-cascader
                  :props="systemOptionProps"
                  v-model="params.system"
                  :options="sysTemList"></el-cascader>
              <div style="margin-left:0.12rem;color:red;font-size: 0.12rem">*可多选</div>
            </div>
          </el-form-item>
          <el-form-item label="界面语言" prop="language">
            <el-radio-group v-model="params.language">
              <el-radio :label="1" border>中文</el-radio>
              <el-radio :label="2" border>英文</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="运行环境" prop="environment">
            <div style="display: flex">
              <el-checkbox-group v-model="params.environment">
                <el-checkbox label="64位" border></el-checkbox>
                <el-checkbox label="32位" border></el-checkbox>
              </el-checkbox-group>
              <div style="margin-left:0.12rem;color:red;font-size: 0.12rem">*可多选</div>
            </div>
          </el-form-item>
          <el-form-item label="软件介绍" prop="introduce" class="ueditor" style="width: calc( 100% - 100px);">
            <VueUeditorWrap
                    v-model="params.introduce"
                    :config="editorConfig"
            ></VueUeditorWrap>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Footer style="z-index:12;margin-top:0.2rem"></Footer>
  </div>
</template>
<script>
import Navbar2 from "./componts/Navbar2";
import { getToken } from "../../utils/auth";
import Helper from "./componts/helper"
import {
  Footer
} from "../../layout/componts";
import {getSoftWareAttributes,getSoftwareCateById, addSoftware} from "../../Api";
import {qiniuUpload} from "../../utils/upload";
import {mapGetters} from "vuex";

import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: "UploadSoftwareIndex",
  components: {
    Navbar2,
    Footer,
    Helper,
    VueUeditorWrap
  },
  mounted() {
    if(!this.isLogin){
            this.$login()
          }
    this.$store.dispatch("upload/getUploadToken").then(() => {});
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    // window.removeEventListener("scroll", this.onScroll);
  },
  // beforeUpdate() {
  //     if(!this.isLogin){
  //       this.$login()
  //     }
  // },
  data() {
    return {
      rules: {
        title: [{required: true, message: "请输入标题", trigger: "blur"}],
        fidPath: [
          {required: true, message: "请选择分类", trigger: "change"},
        ],
        system: [
          {required: true, message: "请选择系统", trigger: "change"},
        ],
        language: [
          {required: true, message: "请选择语言", trigger: "change"},
        ],
        environment: [
          {required: true, message: "请选择环境", trigger: "change"},
        ],
        introduce: [
          {required: true, message: "请编辑软件介绍", trigger: "blur"},
        ],
      },
      typeOptionProps:{
        value: "id",
        label: "title",
        children: "child",
      },
      systemOptionProps:{
        value: "name",
        label: "name",
        children: "children",
        multiple: true,
        emitPath: false
      },
      typeList: [],
      sysTemList: [],
      params:{
        title:'',
        baidu: '',
        xunlei: '',
        quark: '',
        fidPath: [],
        system: [],
        language: '',
        environment: [],
        introduce: '',
        token: getToken()
      },
      editorConfig: {
          toolbars: [[
              // 'fullscreen',
              'source', '|', 'undo', 'redo', '|',
              'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
              'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
              'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
              'directionalityltr', 'directionalityrtl', 'indent', '|',
              'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
              'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
              'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment', 'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
              'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
              'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
              'print', 'preview', 'searchreplace', 'drafts', 'help'
          ]],
          // 编辑器不自动被内容撑高
          autoFloatEnabled: false,
          // 初始容器高度
          initialFrameHeight: 300,
          // 初始容器宽度
          initialFrameWidth: "120%",
          // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
          serverUrl: "https://www.zhishew.com/edu/ueditor/php/controller.php",
          UEDITOR_HOME_URL: "/UEditor/",
      },
    }
  },
  created() {
    getSoftWareAttributes().then(res=>{
      if(res.status === '200'){
        this.sysTemList = res.data
      }
    })
    getSoftwareCateById({id:1990,token:getToken()}).then(res=>{
      if(res.status === '200'){
        this.typeList = res.data
      }
    })
  },
  methods: {
    submit() {
      if(!this.isLogin){
        this.$login()
        return
      }
      if (!this.params.baidu && !this.params.xunlei && !this.params.quark) {
        this.$message.warning('请输入至少一个网盘地址')
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.params))
          params.fidPath = params.fidPath.join(',')
          params.system = params.system.join(',')
          params.environment = params.environment.join(',')
          
          params.filePathArr = []
          if(params.baidu) {
            params.filePathArr.push(params.baidu)
          }
          if(params.xunlei) {
            params.filePathArr.push(params.xunlei)
          }
          if(params.quark) {
            params.filePathArr.push(params.quark)
          }
          delete params.baidu;
          delete params.xunlei;
          delete params.quark;
          params.filePathArr = params.filePathArr.join(',')
          
          addSoftware(params).then(res=>{
            if(res.status === '200'){
              this.$message.success('发布成功')
              this.params.baidu = '';
              this.params.xunlei = '';
              this.params.quark = '';
              this.$refs.form.resetFields()
            }
          })
        }
      });
    },
    //点击上传资源
    zh_uploadFile() {
      if (this.uploadFileObj.isFile) return;
      this.$refs.evfile.click();
    },
    zh_uploadFile_change(evfile) {
      const file = evfile.target.files[0];
      this.uploadFileObj.isFile = true;
      this.uploadFileObj.fileName = file.name;
      qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
        this.observable = res;
        this.startUpload();
      });
    },
  }
};
</script>
<style scoped>
.uploadBox{
  width:12rem;
  background:white;
  margin:auto;
  border-radius:0.08rem;
  min-height:9rem
}
.navHeader{
  position: sticky;
  top: 0px;
  z-index: 9;
}
.title{
  display: flex;
  align-items: center;
  border-bottom:1px solid lightgray;
  padding:0.12rem
}
.title>div:first-child{
  width:50%;
  color: rgba(31, 98, 238, 1);
  font-size: 0.14rem;
  font-weight: 500;
}
.title>div:nth-child(2){
  text-align: right;
  width:50%;
}
.content{
  padding:0.60rem 1.80rem
}
>>> .el-form-item__label{
  color: rgba(59, 59, 59, 1);
  font-size: 0.14rem;
  font-weight: 500;
}
>>> .el-form-item{
  margin-bottom:0.44rem
}
/deep/.el-input__inner{
  border-radius:0.32rem
}

/deep/ .el-radio.is-bordered, /deep/ .el-checkbox.is-bordered{
  border-radius:0.32rem
}
/deep/ .special .el-form-item__content{
  margin-left:20px !important;
}
/deep/ .el-radio__input{
  display: none;
}
/deep/ .el-checkbox__input{
  display: none;
}
/deep/ .el-cascader__tags .el-tag {
  border-radius:0.18rem
}

.ueditor >>> .el-form-item__content {
    line-height: 0;
}
</style>